/**
 * Menu component that queries for data
 * with Gatsby's StaticQuery component
 * 
 * @todo: refactor code to strategies or chainded functions!!! 
 *
 * @see: https://www.gatsbyjs.org/docs/static-query/
 * @see: https://reactjs.org/docs/code-splitting.html#suspense
 */

// @see: https://www.andrewl.net/article/gatsby-drupal-inline-images
// @see: https://www.npmjs.com/package/react-html-parser
// @see: https://gist.githubusercontent.com/andrewl/349410cbd5d9a26cf5286134fe5293df/raw/aecf4eae250e55980e98ec95c4937608f7706e03/article.js
// @todo: Replace relative URL with <Link />

import React, { Component, Suspense } from "react"
// import ReactDOMServer from 'react-dom/server';
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// s4n
import _ from 'lodash'
import { Link } from "gatsby-plugin-intl"
import styleToObject from 'style-to-object';
// import { renderToStaticMarkup } from 'react-dom/server';
import parse, { domToReact } from 'html-react-parser';
import classNames from 'classnames';
import ImageUtils from './../../utils/gatsby/image'

//import Iframe from 'react-iframe';
//import SpeakerDeck from '../components/speakerdeck'
//import Gist from 'react-gist';

// import placeholder comonents START
// forms
import loadable from '@loadable/component'
import Preloader from '../../components/s4n/Preloader'
/*
import FormContactS4n from './../../components/forms/webform/contact_s4n'
import FormHeuresisHarmonogramZgloszenie from './../../components/forms/webform/heuresis_harmonogram_zgloszenie'
import FormHeuresisOcenaJakosci from './../../components/forms/webform/heuresis_ocena_jakosci_'
import HeuresisOutsourcingDiagnozaFi from './../../components/forms/webform/heuresis_outsourcing_diagnoza_fi'
import HeuresisZapytanieSzkolenieS from './../../components/forms/webform/heuresis_zapytanie_o_szkolenie_s'
import HeuresisZapytanieSzkolenieZ from './../../components/forms/webform/heuresis_zapytanie_o_szkolenie_z'
// other
import PromotedFrontPage from './../../components/s4n/Promoted/FrontPage/index'
// charts
import ChartHeuresisOcenaJakosci from './../../components/charts/heuresis_ocena_jakosci_'
*/
// import placeholder comonents STOP

// import BlockImageGallery from './../../components/s4n/block/Gutenberg/BlockImageGallery'
import Fade from 'react-reveal/Fade';
import { globalHistory } from "@reach/router"
import IframeResizer from 'iframe-resizer-react'


// import Slider from "react-slick";



export const useParseInlineImages = (node, intl, wrap = false, oReplacement = {}) => {

    const t = intl.formatMessage;

    const FORM_PREFIX = `s4n_form_`;
    const CHART_PREFIX = `s4n_chart_`;

    // Loadable components START
    // forms
    const FormContactS4n = loadable(() => import('./../../components/forms/webform/contact_s4n'), {fallback: <Preloader />})
    const FormHeuresisHarmonogramZgloszenie = loadable(() => import('./../../components/forms/webform/heuresis_harmonogram_zgloszenie'), {fallback: <Preloader />})
    const FormHeuresisOcenaJakosci = loadable(() => import('./../../components/forms/webform/heuresis_ocena_jakosci_'), {fallback: <Preloader />})
    const HeuresisOutsourcingDiagnozaFi = loadable(() => import('./../../components/forms/webform/heuresis_outsourcing_diagnoza_fi'), {fallback: <Preloader />})
    const HeuresisZapytanieSzkolenieS = loadable(() => import('./../../components/forms/webform/heuresis_zapytanie_o_szkolenie_s'), {fallback: <Preloader />})
    const HeuresisZapytanieSzkolenieZ = loadable(() => import('./../../components/forms/webform/heuresis_zapytanie_o_szkolenie_z'), {fallback: <Preloader />})
    // other
    const PromotedFrontPage = loadable(() => import('./../../components/s4n/Promoted/FrontPage/index'), {fallback: <Preloader />})
    // charts
    const ChartHeuresisOcenaJakosci = loadable(() => import('./../../components/charts/heuresis_ocena_jakosci_'), {fallback: <Preloader />})

    const FormDynamic = loadable(() => import('./../../components/forms/webform/FormDynamic'), {fallback: <Preloader />})
    // Loadable components STOP

    const replacement = {

        /**
         * oReplacement can contain:
         * [`[s4n_related_products]`]
         * [`[s4n_subpages]`]
         */
        ...oReplacement,

        // Loadable components START
        [`[${FORM_PREFIX}contact_s4n]`]: <FormContactS4n />,
        [`[${FORM_PREFIX}heuresis_harmonogram_zgloszenie]`]: <FormHeuresisHarmonogramZgloszenie harmonogramNode={node} />,
        [`[${FORM_PREFIX}heuresis_ocena_jakosci_]`]: <FormHeuresisOcenaJakosci />,
        [`[${FORM_PREFIX}heuresis_outsourcing_diagnoza_fi]`]: <HeuresisOutsourcingDiagnozaFi />,
        [`[${FORM_PREFIX}heuresis_zapytanie_o_szkolenie_s]`]: <HeuresisZapytanieSzkolenieS />,
        [`[${FORM_PREFIX}heuresis_zapytanie_o_szkolenie_z]`]: <HeuresisZapytanieSzkolenieZ />,
        [`[${CHART_PREFIX}heuresis_ocena_jakosci_]`]: <ChartHeuresisOcenaJakosci />,
        [`[s4n_front_page_promoted]`]: <PromotedFrontPage limit={4} />,
        // Loadable components STOP

        // @todo: check this
        // ['lazy_loaded']: React.lazy(() => import('./../../components/forms/webform-contact-s4n')),      

    }

    // const { html } = props; // use when we use this a component and receive props as argument!
    // console.log(html); return;

    const htmlReactParserOptions = {
        replace: (htmlNode, index) => {

            /**
             * If i modify style here as global then it complains that:
             * First argument must be a string.
             * The one passed to cssToJs(style)
             * So i need to modify style right before destructing to react component.
             */
            // // !!! ALWAYS BEFORE DECONSTRUCTING PROPS CONVERT STRING STYLE TO OBJECT !!!
            // if (htmlNode.attribs && htmlNode.attribs.style && typeof htmlNode.attribs.style === 'string') {
            //     // console.log(htmlNode.attribs.style)
            //     htmlNode.attribs.style = styleToObject(htmlNode.attribs.style);
            //     // console.log(htmlNode.attribs.style)
            // }

            /**
             * TEXT WE CAN RETURN FROM TRANSFORM RIGHT AWAY WE DON'T CARE ABOUT ATTRIBS
             * Replace [...] string with component
             * 
             * ReactDOMServer does not yet support Suspense.
             * Causes an error during gatsby build:
             * Error: Invariant Violation: Minified React error #295; visit https://reactjs.org/docs/error-decoder.html?invariant=295 for the full message or use the non-minified dev environment for full errors and additional helpful warnings.
             * @see: https://reactjs.org/docs/error-decoder.html/?invariant=294
             * @see: https://github.com/xing/hops/issues/714
             * @see: https://github.com/gatsbyjs/gatsby/issues/11960
             * @see: https://github.com/smooth-code/loadable-components
             */
            if (
                `text` === htmlNode.type

                // 'tag' === htmlNode.type 
                // // && 'p' === htmlNode.name 
                // && htmlNode.children[0] 
                // && `text` === htmlNode.children[0].type
            ) {
                let Component = null;

                if (htmlNode.data && typeof replacement[htmlNode.data] !== `undefined`) {
                    Component = replacement[htmlNode.data];
                    // Component = loadable(() => import(replacement[htmlNode.data]));
                    // return <Component module={module} />
                } else {
                    // htmlNode.data === [s4n_form_FORM_ID]
                    if (htmlNode.data.includes(FORM_PREFIX)) {
                        // let WEBFORM_ID = htmlNode.data;
                        // WEBFORM_ID = WEBFORM_ID.replace(`[${FORM_PREFIX}`, ``);
                        // WEBFORM_ID = WEBFORM_ID.replace(`]`, ``);
                        const WEBFORM_ID = htmlNode.data.replace(`[${FORM_PREFIX}`, ``).replace(`]`, ``);
// console.log(`WEBFORM_ID: `, WEBFORM_ID)

                        const pathAndHash = globalHistory.location.pathname + globalHistory.location.hash;

                        // Component = <FormDynamic webformId={WEBFORM_ID} />
                        Component = <IframeResizer
                            // id={CHECKOUT_IFRAME_ID}
                            // forwardRef={iframeRefCheckout}
                            // src={`//heuresis.test/pl/webform/heuresis_harmonogram_zgloszenie/share/iframe-resizer/4.2.10?Q=/pl/admin/structure/webform/manage/heuresis_harmonogram_zgloszenie/share`}
                            src={`${process.env.SOFT4NET_SOURCE_BASE_URL}/${intl.locale}/webform/${WEBFORM_ID}/share/iframe-resizer/4.2.10?Q=/pl/admin/structure/webform/manage/${WEBFORM_ID}/share&source_uri=${encodeURI(pathAndHash)}`}
                            title={`Embedded webform form`}
                            className="webform-share-iframe" 
                            frameBorder="0"
                            allow="geolocation; microphone; camera" 
                            allowTransparency
                            allowFullScreen
                            style={{
                                width: `1px`,
                                minWidth: `100%`,
                            }}

                            inPageLinks
                            scrolling="no"
                            // checkOrigin={false}
                            checkOrigin={[
                                process.env.SOFT4NET_SOURCE_BASE_URL,
                            ]}
                            // heightCalculationMethod="bodyOffset"
                            heightCalculationMethod="lowestElement"
                        />
                    }
                }
/*
                switch (htmlNode.children[0].data) {
                    case '[webform_contact_s4n]':
                        // Component = <FormContactS4n />;
                        Component = React.lazy(() => import('./../../components/forms/webform-contact-s4n'));
                        break;
                    case '[webform_harmonogram_s4n]':
                        Component = React.lazy(() => import('./../../components/forms/webform-harmonogram-s4n'));
                        break;
                    case '[webform_quality_check_s4n]':
                        Component = React.lazy(() => import('./../../components/forms/webform-quality-check-s4n'));
                        break;
                    // ...
                }
*/
                if (Component) {
                    return Component;
                    // return <Component />;
                    // return (
                    //     <Suspense fallback={<div>Loading...</div>}>
                    //         <Component />
                    //     </Suspense>
                    // )
                }
            }

            if ('tag' === htmlNode.type && 'a' === htmlNode.name) {
                // console.log(htmlNode)

                // Do not process link that opens in new tab!!! DO NOT CONVERT THEM TO <Link> !!!
                if (`_blank` === htmlNode.attribs['target']) {
                    return undefined;
                }

                // If it's special href not ordinary link skip processing
                // https://stackoverflow.com/questions/37896484/multiple-conditions-for-javascript-includes-method
                if ([`http:`, `https:`, `mailto:`, `tel:`].some(el => htmlNode && htmlNode.attribs['href'] && htmlNode.attribs['href'].includes(el))) {
                    return undefined;
                }

                // Set proper href for wrapping anchor if was set in backend - GALLERY
                if (htmlNode.attribs.href && htmlNode.attribs.href.startsWith('/sites/default/files/')) {
                    if ('tag' === htmlNode.children[0].type && 'img' === htmlNode.children[0].name) {
                        let uuid = htmlNode.children[0].attribs['data-entity-uuid'],
                        img = ImageUtils.getGatsbyImageDataByUuid(uuid, node);
                    
                        if (img) {
                            htmlNode.attribs.href = img.src;    
                        }
                    } else {
                        htmlNode.attribs.href = `${process.env.SOFT4NET_SOURCE_BASE_URL}${htmlNode.attribs.href}`; // link to backend files directly!
                        return htmlNode;
                    }
                }

                // Gutenberg file download
                // if (
                //     false
                //     && htmlNode.attribs && htmlNode.attribs.download 
                //     // || htmlNode.attribs['class'].includes('wp-block-file__button')
                // ) {

                //     // !!! ALWAYS BEFORE DECONSTRUCTING PROPS CONVERT STRING STYLE TO OBJECT !!!
                //     if (htmlNode.attribs && htmlNode.attribs.style && typeof htmlNode.attribs.style === 'string') {
                //         htmlNode.attribs.style = styleToObject(htmlNode.attribs.style);
                //     }

                //     return <a {...htmlNode.attribs} >
                //         { t({ id: "soft4net_node_harmonogram_coach" }) }
                //     </a>
                // }



                // @todo: This should just convert to <Link> tag not do replacing!!!
                if (`text` === htmlNode.children[0].type) {

                    let linkTo = htmlNode.attribs['href'];

                    // Parse anchor HREFs START
                    const checkHref = [
                        '/' + intl.locale, // '/en/page-url' => '/page-url'
                        process.env.SOFT4NET_SOURCE_BASE_URL + '/' + intl.locale, // 'https://www.domain.tld/en/page-url' => '/page-url' | removes prefix added in administration!
                    ];

                    checkHref.forEach(hrefStartsWith => {
                        if (htmlNode.attribs.href && htmlNode.attribs.href.startsWith(hrefStartsWith)) {
                            linkTo = htmlNode.attribs['href'].replace(hrefStartsWith, '');
                        }
                    });

                    // change only link that has text type inside not other elements
                    if (linkTo) {
                        delete htmlNode.attribs['href'];

                        // !!! ALWAYS BEFORE DECONSTRUCTING PROPS CONVERT STRING STYLE TO OBJECT !!!
                        if (htmlNode.attribs && htmlNode.attribs.style && typeof htmlNode.attribs.style === 'string') {
                            htmlNode.attribs.style = styleToObject(htmlNode.attribs.style);
                        }

                        return <Link to={linkTo} {...htmlNode.attribs} >
                            { htmlNode.children[0].data }
                        </Link>
                    }
                    // Parse anchor HREFs STOP
                }

            }

            if ('tag' === htmlNode.type && 'img' === htmlNode.name) {
// console.log(`htmlNode: `, htmlNode);

                /**
                 * In Drupal after uploading file via WYSWIG editor:
                 * <img 
                 *  alt="Tekst alternatywny" 
                 *  data-entity-type="file" 
                 *  data-entity-uuid="ebd86225-bd84-4d7e-8ffd-074a011e0a90" 
                 *  src="/sites/default/files/inline-images/Screenshot%202019-05-16%20at%2013.37.58.png" />
                 */

                // @todo: Replace relative URL with <Link />
                
                let uuid = htmlNode.attribs['data-entity-uuid'],
                    img = ImageUtils.getGatsbyImageDataByUuid(uuid, node);
              
                if (img) {

                    // // Set proper href for wrapping anchor if was set in backend
                    // let htmlNodeParent = htmlNode.parent;
                    // if ('tag' === htmlNodeParent.type && 'a' === htmlNodeParent.name) {
                    //     if (htmlNodeParent.attribs.href && htmlNodeParent.attribs.href.startsWith('/sites/default/files/')) {
                    //         console.log(htmlNodeParent.attribs.href)
                    //         console.log(htmlNodeParent.attribs.href.startsWith('/sites/default/files/'))
                    //         htmlNodeParent.attribs.href = `${process.env.SOFT4NET_SOURCE_BASE_URL}${htmlNodeParent.attribs.href}` // THIS DO NOT WORK, WHY???!!!
                    //         console.log(htmlNodeParent.attribs.href)
                    //     }
                    // }

                    if (
                        _.isEmpty(htmlNode.attribs['alt'])
                        // `` === htmlNode.attribs['alt']
                        // || typeof htmlNode.attribs['alt'] === `undefined` 
                    ) {
                        let oDrupalMediaImageData = ImageUtils.getDrupalMediaImageDataByUuid(uuid, intl.locale, node);
                        htmlNode.attribs['alt'] = oDrupalMediaImageData ? oDrupalMediaImageData.alt : ``;

                        // if (img.alt) {
                        //     htmlNode.attribs['alt'] = img.alt;
                        // }
                    }

                    let htmlNodeParent = null;
                    let useGatsbyImage = true;
                    let gatsbyImageLoadingEager = false;

                    htmlNodeParent = htmlNode;
                    while (htmlNodeParent = htmlNodeParent.parent) {
                        // For all OwlCarousel images disable Gatsby image, except hero and standard slider!
                        if (
                            // useGatsbyImage &&
                            'tag' === htmlNodeParent.type && 'figure' === htmlNodeParent.name
                            && htmlNodeParent?.attribs?.['class']?.includes('owl-carousel') && !htmlNodeParent?.attribs?.['class']?.includes('use-gatsby-image')
                            // && !htmlNodeParent.attribs['class'].includes('hero')
                        ) {
                            // console.log(htmlNodeParent)
                            useGatsbyImage = false;
                            break;
                        }
                    }

                    htmlNodeParent = htmlNode;
                    while (htmlNodeParent = htmlNodeParent.parent) {
                        if (
                            // !gatsbyImageLoadingEager &&
                            'tag' === htmlNodeParent.type && 'figure' === htmlNodeParent.name
                            && htmlNodeParent?.attribs?.['class']?.includes('gatsby-image-loading-eager')
                        ) {
                            gatsbyImageLoadingEager = true;
                            break;
                        }
                    }

                    // !!! ALWAYS BEFORE DECONSTRUCTING PROPS CONVERT STRING STYLE TO OBJECT !!!
                    if (htmlNode?.attribs?.style && typeof htmlNode.attribs.style === 'string') {
                        htmlNode.attribs.style = styleToObject(htmlNode.attribs.style);
                    }

                    if (useGatsbyImage) {
                        return <Img 
                            fluid={img}
                            {...htmlNode.attribs}
                            {...(gatsbyImageLoadingEager ? { loading: "eager" } : {} )}
                            // loading="eager"
                            // objectFit="cover"
                            // width="1"
                            // height="1"
                        />
                    } else {
                        // console.log(htmlNode)
                        // return <img src={img.src} alt={htmlNode.attribs['alt']} />

                        // console.log(htmlNode.attribs);

                        // class="owl-lazy" data-src="https://placehold.it/350x450&text=1"

                        // const htmlNodeAttribs = {
                        //     ...htmlNode.attribs
                        // }

                        // @see: https://owlcarousel2.github.io/OwlCarousel2/demos/lazyLoad.html
                        delete htmlNode.attribs['src'];
                        // htmlNode.attribs['src'] = `https://www.eaglevillemotors.com/1/images/loading-gear.gif`;
                        htmlNode.attribs['data-src'] = img.src;
                        htmlNode.attribs['class'] = classNames({
                            [`${htmlNode.attribs['class']}`]: true,
                            [`owl-lazy`]: true,
                        });

                        if (htmlNodeParent?.attribs?.['class'].includes('hero')) {
                            return <div {...htmlNode.attribs}></div>
                        }
                        
                        return <img {...htmlNode.attribs} />
                    }
                }
/*
                else {
                    return undefined;

                    // we need to change property class to className for React! ???
                    htmlNode.attribs['className'] = htmlNode.attribs['class'];
                    delete htmlNode.attribs['class'];

                    htmlNode.attribs['src'] = `${process.env.SOFT4NET_SOURCE_BASE_URL}${htmlNode.attribs['src']}`
                    return <img {...htmlNode.attribs}/>
                }
*/

                //return null;

                // not performant refactor not to be inside transform, this rusn for each HTML img node!

                // for (let i = 0; i < data.allFileFile.edges.length; i++) {
                //     if (data.allFileFile.edges[i].node.drupal_id === uuid && data.allFileFile.edges[i].node.localFile) {
                //         return <Img fluid={data.allFileFile.edges[i].node.localFile.childImageSharp.fluid}/>
                //     }
                // }

                // data.allFileFile.edges.map(({ node }) => {
                //     if (node.drupal_id === uuid && node.localFile) {
                //         return <Img fluid={node.localFile.childImageSharp.fluid}/>
                //     }
                // })

            }

            // Gutenberg widget "Cover"
            if ('tag' === htmlNode.type && 'div' === htmlNode.name && htmlNode.attribs && htmlNode.attribs.class && htmlNode.attribs.class.includes('wp-block-cover')) {
                let uuid = htmlNode.attribs['data-entity-uuid'],
                    img = ImageUtils.getGatsbyImageDataByUuid(uuid, node);
                
                if (img) {
                    
                    // !!! ALWAYS BEFORE DECONSTRUCTING PROPS CONVERT STRING STYLE TO OBJECT !!!
                    if (htmlNode.attribs && htmlNode.attribs.style && typeof htmlNode.attribs.style === 'string') {
                        htmlNode.attribs.style = styleToObject(htmlNode.attribs.style);
                        htmlNode.attribs.style = {
                            ...htmlNode.attribs.style,
                            'background-image': `url('${img.src}')`,
                        }

                        // console.log(htmlNode.attribs.style)

                        return (
                            <div {...htmlNode.attribs} >
                                {domToReact(htmlNode.children, htmlReactParserOptions)}
                            </div>
                        );

                        // @see: https://stackoverflow.com/questions/45205593/how-to-convert-a-json-style-object-to-a-css-string
                        // const styleString = (
                        //     Object.entries(htmlNode.attribs.style).reduce((styleString, [propName, propValue]) => {
                        //         return `${styleString}${propName}:${propValue};`;
                        //     }, '')
                        // );
                        // htmlNode.attribs.style = styleString;
                        // return domToReact(htmlNode, htmlReactParserOptions); // Alter the object here to suit your needs
                    }
                }
            }

            // Change owl-carouse from ul/li to div
            if ('tag' === htmlNode.type && 'ul' === htmlNode.name && htmlNode.attribs && htmlNode.attribs.class && htmlNode.attribs.class.includes('blocks-gallery-grid')) {  

//                 const settings = {
//                     dots: true,
//                     infinite: true,
//                     speed: 500,
//                     slidesToShow: 1,
//                     slidesToScroll: 1
//                 };

//                 return (
//                     <Slider {...settings}>
//                         {htmlNode.children.map((node, index) => {
//                             node.attribs['class'] = classNames({
//                                 [`item`]: true,
//                                 [`${node.attribs['class']}`]: true,
//                             });

//                             return (
//                                 <div key={`owl-carousel-item-${index}`} {...node.attribs} data-aaa="aaa"> {/* .item.blocks-gallery-item */}
//                                     {domToReact(node.children, htmlReactParserOptions)}

//                                     {/* {node.children.map((node, index) => {
// console.log(node) // name === figure
//                                         const imgNode = node.children[0];
//                                         const uuid = imgNode.attribs['data-entity-uuid'];
//                                         const img = ImageUtils.getGatsbyImageDataByUuid(uuid, node);
// console.log(img)
//                                     })} */}

//                                 </div>
//                             )
//                         })}
//                     </Slider>
//                 );

                return (
                    <div {...htmlNode.attribs}>
                        {htmlNode.children.map((node, index) => {
                            node.attribs['class'] = classNames({
                                [`item`]: true,
                                [`${node.attribs['class']}`]: true,
                            });

                            return (
                                <div key={`owl-carousel-item-${index}`} {...node.attribs}> {/* .item.blocks-gallery-item */}
                                    {domToReact(node.children, htmlReactParserOptions)}
                                </div>
                            )
                        })}
                    </div>
                );



                // return <BlockImageGallery
                //   // {...attributes}
                //   // key={index}
  
                //   anchor={`#BlockImageGallery`}
                //   images={slides}
                //   // imagesRendered={slides}
  
                // />
            }

            // if ('transformedNodeAttribsStyle' === isTransformed) {
            //     return domToReact(htmlNode, htmlReactParserOptions); // Alter the object here to suit your needs
            // }



            // else if (htmlNode.type === 'tag' && htmlNode.name === 'iframe') {
            //     let url = htmlNode.attribs.src;
        
            //     if (url.search('http') === -1) {
            //         if (url.search('//') === -1) {
            //             url = "//" + url;
            //         }
                    
            //         url = "https:" + url;
            //     }
        
            //     let id = url.split("/").pop();
                
            //     return <Iframe
            //         url={url}
            //         width={htmlNode.attribs.width}
            //         height={htmlNode.attribs.height}
            //         id={id}
            //         display="initial"
            //         position="relative"
            //         allowFullScreen
            //     />;
            // }
            // else if (htmlNode.type === 'script') {
            //     if ('src' in htmlNode.attribs && 'data-id' in htmlNode.attribs) {
            //         if (htmlNode.attribs['src'].search('speakerdeck') > -1) {
            //             return <SpeakerDeck data-id={htmlNode.attribs['data-id']} />;
            //         }
            //     } else if ('src' in htmlNode.attribs) {
            //         if (htmlNode.attribs['src'].search('gist.github') > -1) {
            //             var id = htmlNode.attribs['src'].split('/').pop().split('.')[0];
            //             return <Gist id={id} />;
            //         }
            //     }

            // }

            // If the function does not return anything, or returns undefined, then the default behaviour will occur and the parser will continue was usual.
            // return undefined;



            // Gallery add figcaption to anchors if not present!
            if (`tag` === htmlNode.type && `figure` === htmlNode.name) {
                let figure = htmlNode;
                let figcaption = null;
                let figcaptionAnchor = null;
                let figcaptionChildrenAnchor = null;
                let figcaptionChildrenAnchorText = null;

                if (typeof figure.children[0] !== `undefined` && 'tag' === figure.children[0].type && 'a' === figure.children[0].name) {
                    figcaptionAnchor = figure.children[0];
                    // console.log(figcaptionAnchor)
                }

                if (typeof figure.children[1] !== `undefined` && 'tag' === figure.children[1].type && 'figcaption' === figure.children[1].name
                    && figure.children[1].attribs && figure.children[1].attribs.class && figure.children[1].attribs.class.includes(`blocks-gallery-item__caption`)) {
                    figcaption = figure.children[1];
                    // console.log(figcaption)

                    if (figcaption.children[0] && 'tag' === figcaption.children[0].type && 'a' === figcaption.children[0].name) {
                        figcaptionChildrenAnchor = figcaption.children[0];

                        if (`text` === figcaptionChildrenAnchor.children[0].type) {
                            figcaptionChildrenAnchorText = figcaptionChildrenAnchor.children[0];
                            // console.log(figcaptionChildrenAnchorText.data); // Figcaption text!

                            if (figcaptionChildrenAnchorText && figcaptionChildrenAnchorText.data !== ``) {
                                if (typeof figcaptionChildrenAnchor.attribs.title === `undefined`) {
                                    figcaptionChildrenAnchor.attribs.title = figcaptionChildrenAnchorText.data;
                                }
                            }
                        }
                    }
                }

                if (figcaptionChildrenAnchorText && figcaptionChildrenAnchorText.data !== ``) {
                    // if (typeof figcaptionAnchor?.attribs.title === `undefined`) {
                    if (!figcaptionAnchor?.attribs?.title) {
                        // figcaptionAnchor.attribs.title = figcaptionChildrenAnchorText.data;
                        figcaptionAnchor = {
                            attribs: {
                                title: figcaptionChildrenAnchorText.data
                            }
                        };
                    }
                }

            }



            // // Gutenberg widget "Files"
            // if ('tag' === htmlNode.type && 'div' === htmlNode.name && htmlNode?.attribs?.class.includes('wp-block-file')) {
            //     htmlNode.children.map(children => console.log(children.attribs))
            // }



            // @see: https://www.react-reveal.com/examples/common/
            if ('tag' === htmlNode.type && 'div' === htmlNode.name && htmlNode.attribs && htmlNode.attribs.class && htmlNode.attribs.class.includes('timeline')) {  
                let counter = 0;
                return (
                    <div {...htmlNode.attribs}>
                        {htmlNode.children.map((node, index) => {
                            if (!(node.attribs && node.attribs.class && node.attribs.class.includes('timeline-item'))) {
                                return null;
                            }
// console.log(node)
// console.log(counter)
                            return (
                                <div {...node.attribs}>                                   
                                    {++counter%2 === 0 ?
                                        <Fade right>
                                            <div className={node.children[2].attribs.class}>
                                                {domToReact(node.children[2].children, htmlReactParserOptions)}
                                            </div>
                                        </Fade>
                                        :
                                        <Fade left>
                                            <div className={node.children[2].attribs.class}>
                                                {domToReact(node.children[2].children, htmlReactParserOptions)}
                                            </div>
                                        </Fade>
                                    }
                                </div>
                            )
                        })}
                    </div>
                )
            }


        },
    };

    const html = node?.body?.value || ``;

    let htmlReactParserParsedHtml = parse(html, htmlReactParserOptions);

    // CHECK IF WE HAVE WRAPPING .container element START
    if (wrap) {
        // const hasContainer = ReactDOM.findDOMNode(<instance-of-outermost-component />).getElementsByClassName('snap').length
        // const hasContainer = ReactDOM.findDOMNode(nodeBodyField).getElementsByClassName('container').length;

        // ReactDOM.findDOMNode(

        // const hasContainer = ReactDOM.findDOMNode(nodeBodyField).getElementsByClassName('container');
        // console.log(hasContainer)

        // console.log(nodeBodyField)

        // const hasContainerRecursive = (html) => {
        //     if (`undefined` !== typeof html.props.className && `container` === html.props.className) {
        //         return true;
        //     }
        //     return hasContainerRecursive(html.props.children);
        // }
        // const hasContainer = hasContainerRecursive(nodeBodyField);

        // React.Children.forEach(nodeBodyField, child => {
        //   const nestedChildren = new child.type(child.props).render();

        //   console.log(nestedChildren); // `FooTuple` children
        // });

        // console.log(hasContainer)
        
        // let snapCount = React.Children.toArray(nodeBodyField).filter((item) => {
        //   console.log(item)
        //   return item.props.className === 'container'
        // }).length;


        // class Abc extends React.Component {
        //   render() {
        //       return nodeBodyField
        //   }
        // };

        // const hasContainer = ReactDOM.findDOMNode(<Abc />).getElementsByClassName('container');
        // console.log(hasContainer)

        // const childrenWithWrapperDiv = React.Children.map(nodeBodyField, child => {
        //   console.log(child)
        //   return (
        //     <div className="some-component-special-class">{child}</div> 
        //   );
        // });

        // @see: https://stackoverflow.com/questions/32916786/react-children-map-recursively
        // @see: https://stackoverflow.com/questions/30971395/difference-between-react-component-and-react-element
        const recursiveMap = (children, callback) => {
            return React.Children.map(children, child => {
                if (!React.isValidElement(child)) {
                    return child;
                }

                if (child.props.children) {
                    child = React.cloneElement(child, {
                        children: recursiveMap(child.props.children, callback)
                    });
                }

                return callback(child);
            });
        }

        let classToBeFound = `container`;
        let hasContainer = false;
        recursiveMap(htmlReactParserParsedHtml, (child) => {
            if (`undefined` !== typeof child.props.className && child.props.className.includes(classToBeFound)) {
                hasContainer = true;
            }
        })

        // console.log(hasContainer)

        if (htmlReactParserParsedHtml.length && !hasContainer) {
            htmlReactParserParsedHtml = (
                <div className="container">
                    { htmlReactParserParsedHtml }
                </div>
            )
        }
    }
    // CHECK IF WE HAVE WRAPPING .container element STOP






    // @see: https://reactjs.org/docs/react-dom-server.html
    //htmlReactParserParsedHtml = ReactDOMServer.renderToString(htmlReactParserParsedHtml);

    // You can use the react-html-parser in case you don't want to use dangerouslySetInnerHTML attribute
    return htmlReactParserParsedHtml;
    // return renderToStaticMarkup(htmlReactParserParsedHtml);
}

// useParseInlineImages.propTypes = {
//     html: PropTypes.string.isRequired,
// }

// export default useParseInlineImages